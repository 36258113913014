#landing-page {

  // .landing-container {
  //   width: 962px;
  //   margin-left: auto;
  //   margin-right: auto;
  // }

  h3 {
    font-weight: 100;
    color: gray;
    font-size: 32px;
    padding: 40px 0 20px 0;
  }

  .single-source {
    h3 {
      font-weight: 100;
      padding: 20px;
      line-height: 44px;
    }
  }

  .btn:hover {
    color: white;
  }

  .hero-wrapper {
    position: relative;
    &:before {
      content: ' ';
      position: absolute;
      left: 0px;
      right: 0px;
      bottom: 0px;
      top: 0px;
      z-index: -1;
      background: rgba(0,0,0,0.3);
    }
    z-index: 999;
    width: 100%;
    background-image: url('../img/hero.jpg');
    background-size: cover;
    background-position: 0px 0px;
    color: white;
    height: 600px;
  }

  .hero-content {

    padding: 20px;
    padding-top: 80px;

    h1 {
      margin-top: 50px;
      margin-bottom: 10px;
      font-size: 60px;
      // padding-bottom: 18px;
      font-weight: bold;
      line-height: 60px;

      @include screen-sm {
        font-size: 50px;
        line-height: 60px;
      }
    }

    h2 {
      font-size: 27px;
      max-width: 700px;
      font-weight: bold;
      line-height: 40px;
      @include screen-sm {
        margin-top: 40px;
        font-size: 22px;
        line-height: 30px;
      }
    }

    .cta {
      margin-top: 50px;
    }

  }

  .content-section {
    padding: 20px 0;
  }

  .gray-background {
    background-color: #FAFAFA;
  }

  .carousel-indicators {
    position: relative;
    top: 20px;
  }

  .main-features h5 {
    font-size: 17px;
    text-transform: uppercase;
  }

  h4 {
    font-weight: 320;
    font-size: 22px;
    line-height: 38px;
  }

  .featured-listings {
    padding-bottom: 15px;

    h3 {
      padding: 10px 0 20px 0;
    }

    .image-lineup {
      li {
        margin-bottom: 20px;
      }
    }

    .image-lineup .image {
      position: relative;
      height: 200px;
      background-position: center;
      background-size: cover;
    }

    .image-lineup .image-building-reports {
      height: 280px;
    }

    .image-lineup .image .subtitle-background {
      position: absolute;
      background-color: black;
      width: 100%;
      height: 30px;
      bottom: 0px;
      opacity: 0.5;
    }
    .image-lineup .image .subtitle {
      position: absolute;
      color: white;
      bottom: 0px;
      line-height: 30px;
      padding-left: 10px;
      text-transform: uppercase;
      font-weight: bold;
    }
    

    .carousel-indicators {
      color: black;
      margin-top: 15px;
      
      li {
        display: inline-block;
        height: 20px;
        width: 20px;
        float: none;
        background-color: #ccc;
        -webkit-border-radius: 20px;
        -moz-border-radius: 20px;
        border-radius: 20px;
      }
      li:hover {
        background-color: gray;
        cursor: pointer;
      }
      li.active {
        background-color: gray;
      }
    }

  }

  

  .signup-text-content {

    background-color: #FAFAFA;

    ul.benefits {
      li {
        font-weight: 200;
        font-size: 20px;
        line-height: 36px;

        @include screen-sm {
          // text-align: center;
        }

        @include screen-sm {
          text-align: center;
          font-size: 16px;
          line-height: 30px;
        }
      }
    }

    a {
      margin-top: 15px;
      // background-color: #46555D;
    }

    .btn {
      font-weight: bold;
      padding-left: 30px;
      padding-right: 30px;
    }

    a:hover {
      color: white;
    }
  }

  .single-source-wrapper {
    margin: 50px 0 30px 0;
    margin-left: auto;
    margin-right: auto;
  }

  .book-wrapper {
    height: 600px;
    width: 400px;
    background-size: 80px 60px;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url('../img/nyr_book_image.png');
  }

  .book h3 {
    font-weight: 700;
    color: #46555D;
    font-size: 32px;
    margin-top: 80px;
  }

  .book p {
    font-weight: 200;
    color: #46555D;
    font-size: 28px;
    letter-spacing: 1px;
    line-height: 40px;
    margin-bottom: 40px;
  }

  .hero-bottom-wrapper {
    width: 100%;
    background-image: url('../img/hero-bottom.jpg');
    background-size: cover;
    color: white;
    height: 430px;
    background-position: 0px -100px; 
    text-align: center;
  }

  .hero-bottom-container {
    width: 800px;
    margin-left: auto;
    margin-right: auto;
  }


  .hero-bottom-content h3 {
    margin-top: 0px;
    padding-top: 50px;
    padding-bottom: 20px;
    color: white;
    font-size: 40px;
  }

  .hero-bottom-content p {
    color: white;
    font-size: 20px;
    line-height: 40px;
    font-weight: 200;
    padding-bottom: 20px;
  }

  .about {
    text-align: center;
    color: #46555D;
  }

  .about h3 {
    margin-top: 0px;
    padding-top: 80px;
  }
  .about p {
    font-weight: 300;
    font-size: 22px;
    margin: 30px 0 40px 0;
    line-height: 40px;
  }

  .about .btn {
    margin-bottom: 40px;
  }

}
