.listings-page {

  // email me contact me buttons
  .right_button {
    .pull-right {
      @include screen-sm {
        float: none;
      }
    }
  } 

  .refine-search {
      position: absolute;
      top: 0;
      right: 0;
      vertical-align: middle;
      height: 80px;
      line-height: 80px;
      padding-right: 20px;

      @include screen-sm {
        position: relative !important;
      }
  }

  .search-signup-box {
    position: relative;
    line-height: 20px;
    background-color: #EEEEEE;
    padding: 20px 20px 20px 20px;

    h3 {
      font-weight: bold;
      line-height: 28px;
      font-size: 21px;
      text-transform: none;
    }

    .signup-button {
      // position: absolute;
      // top: 0;
      // right: 0;
      // height: 80px;
      padding-right: 20px;
      margin-top: 10px;
    }
  }

  .listing-image-container {
    width: auto !important;

    @include screen-tablet {
      height: 330px !important;
    }

    .listing-image-large {
      display: none;

      width: 100%;
      height: 100%;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;

      &.active {
        display: block;
      }
    }
  }


  .search-result-grid {

    .listing-agent {
      float: right;
      width: 200px;
      padding: 5px 5px 5px 5px;
      background-color: #eee;
      font-size: 11px;
      line-height: 15px;
      margin-top: 40px;

      @include screen-sm {
        float: none !important;
        width: 100%;
      }
    }

    .view-buttons { }


    .register-now-box {
      background-color: #e0e0e0;
      padding: 10px;
      a, a:active, a:visited {
        text-transform: none;
      }
      h3 {
        text-transform: none;
        font-weight: 100;
        font-size: 18px;
        line-height: 27px;
      }
    }

  }



}