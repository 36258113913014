body {
  padding: 0px !important;
}

// add padding to the body via contents
#contents {
  box-sizing: border-box;
  @include screen-tablet {
    box-sizing: border-box;
    padding: 0px 10px;
  }
}
#footer {
  @include screen-tablet {
    padding: 0px 10px;
  } 
}

.container {
  max-width: 962px;
  @include screen-sm {
    padding: 0px 10px;
    margin: 0px 10px;
  }
}

.header {

  .brand {
    @include screen-tablet {
      text-align: center;
      width: 100% !important;
    }
  }

  #desktop-nav,
  #mobile-nav {
    .nav li {
      a, a:active, a:visited {
        font-weight: bold;
        text-shadow: none !important;
        text-decoration: none;
        text-transform: uppercase;
        font-size: 10pt;
        background: transparent !important;
        padding: 8px 8px !important;
        line-height: 16px !important;
      }
      .dropdown-menu {
        a:hover {
          background: #eeeeee !important;
        }
      }
    }
  }

  #mobile-nav {
    display: none;
    margin: 0px 10px;

    .nav li {
      a, a:active, a:visited {
        color: #fff !important;
      }
      a:hover {
        background: transparent !important;
      }
      .dropdown-menu {
        display: block !important;
      }
    }
    @include screen-tablet {
      display: block;
    }
  }

  #desktop-nav {
    margin: 85px 0 0 0;

    @include screen-tablet {
      display: none !important;
    }

    .nav li {
      a, a:active, a:visited {
        color: #000 !important;
      }
      a:hover {
        background: transparent !important;
      }
    }
  }

  .navbar-inner {
    background-color: #fff !important;
    background-image: none;
    padding: 5px 10px !important;
    min-height: 0px !important;
    border: 0px;
    box-shadow: none !important;
    border-radius: 0px !important;

    @include screen-tablet {
      background: #708593 !important;
    }
  }

  .btn-navbar {
    background: none;
    box-shadow: none;
    -webkit-box-shadow: none;
    text-align: center;

    &:hover {
      background: none;
    }

    .icon-bar {
      background: #ffffff;
      border-bottom: 1px solid #ffffff;
      box-shadow: none;
      -webkit-box-shadow: none;
    }
  }

  .login-box {
    li {
      a.login-text {
        background: none !important;
      }
    }
  }

}

