
// bootstrap
$screen-xs: 480px;
$screen-sm: 768px;
$screen-md: 992px;
$screen-lg: 1200px;


@mixin screen-lg {
  @media (max-width: $screen-lg) {
    @content;
  }
}
@mixin screen-lg-only {
  @media (min-width: $screen-lg) {
    @content;
  }
}

@mixin screen-md {
  @media (max-width: $screen-md) {
    @content;
  }
}
@mixin screen-md-plus {
  @media (min-width: $screen-md) {
    @content;
  }
}

@mixin screen-sm {
  @media (max-width: $screen-sm) {
    @content;
  }
}

@mixin screen-tablet {
  @media (max-width: 979px) {
    @content;
  }
}